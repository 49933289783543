import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export const routes = [
  {
    path: "/client",
    component: () => import("@/views/Client"),
    hidden: true,
  },
  {
    path: "/appointment-error",
    component: () => import("@/views/AppointmentError"),
    hidden: true,
  },
  {
    path: "/end-flow",
    component: () => import("@/views/EndFlow"),
    hidden: true,
  },
  {
    path: "/atencion-veterinaria",
    component: () => import("@/views/VeterinarianService"),
    hidden: true,
  },
  {
    path: "/especialidad-atencion",
    component: () => import("@/views/VeterinarianServiceSpec"),
    hidden: true,
  },
  {
    path: "/search-date",
    component: () => import("@/views/Schedules"),
    hidden: true,
  },
  {
    path: "/cancelar-cita",
    component: () => import("@/views/CancelDate"),
    hidden: true,
  },
  {
    path: "/",
    component: () => import("@/views/Home"),
    hidden: true,
  },
];

const router = new Router({
  scrollBehavior: () => ({ y: 0 }),
  routes: routes,
});
export default router;
