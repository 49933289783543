import "bootstrap";
import Vue from "vue";
import App from "./App.vue";
import Vuex from "vuex";
import router from "@/router";
import vSelect from "vue-select";
import Vuelidate from "vuelidate";
import "vue-select/dist/vue-select.css";
import "bootstrap/dist/css/bootstrap.min.css";
require("./plugins/fontawesome");

Vue.config.productionTip = false;

Vue.use(Vuex);
Vue.use(Vuelidate);
Vue.component("v-select", vSelect);

const store = new Vuex.Store({
    state: {
        initData: {
            branchs: [],
            veterinaries: [],
            specialties: [],
        },
        reasons: [],
        species: [],
        scheduleAvailable: null,
        schedules: [],
        day: "",
        branchs: [{
            id: "0",
            name: "No tengo preferencia",
            descripcion: "No tengo preferencia",
        }, ],
        daysAvailable: [],
        veterinaries: [{
            id: "0",
            name: "No tengo preferencia",
        }, ],
        specialties: [{
            id: "0",
            name: "No tengo preferencia",
        }, ],
        isFiltered: true,
        isDataLoaded: false,
        client: {
            rut: null,
            first_name: "",
            last_name: "",
            phone: "",
            email: "",
            reason: "",
            nivel: "",
            registro: "Cliente5",
            pet_id: null,
            pets: [],
        },
        date: {
            day: "",
            hour: "",
            office: "",
            petName: "",
            veterinary: "",
            reason: "",
        },
        queryForm: {
            type: "",
            branch_office_id: "0",
            veterinary_id: "0",
            specialty_id: "0",
            startDay: "",
            endDay: "",
            startHour: "",
            endHour: "",
            specialty: "",
        },
    },

    mutations: {
        updateScheduleAvailable(state, schedule) {
            state.scheduleAvailable = schedule;
        },

        resetQueryForm(state) {
            state.queryForm = {
                type: "",
                branch_office_id: "0",
                veterinary_id: "0",
                specialty_id: "0",
                startDay: "",
                endDay: "",
                startHour: "",
                endHour: "",
            };
        },

        updateSchedules(state, schedule) {
            state.schedules = schedule;
        },
        setDate(state, date) {
            state.date = date;
        },
        setInitData(state, data) {
            state.initData = data;
        },

        setReasons(state, reasons) {
            state.reasons = reasons;
        },
        setSpecies(state, species) {
            state.species = species;
        },
        setClient(state, client) {
            state.client = client;
        },
        updateDay(state, day) {
            state.day = day;
        },
        updateBranchs(state, branchs) {
            const firstValue = {
                id: "0",
                name: "No tengo preferencia",
                descripcion: "No tengo preferencia",
            };
            state.branchs = [firstValue].concat(branchs);
        },
        updateVeterinaries(state, veterinaries) {
            const firstValue = {
                id: "0",
                name: "No tengo preferencia",
            };
            state.veterinaries = [firstValue].concat(veterinaries);
        },
        updateSpecialties(state, specialties) {
            const firstValue = {
                id: "0",
                name: "No tengo preferencia",
            };
            state.specialties = [firstValue].concat(specialties);
        },
        setTypeSearch(state, type) {
            state.queryForm.type = type;
        },
        setQueryForm(state, form) {
            state.queryForm = form;
        },
        setBranch(state, office) {
            state.queryForm.branch_office_id = office;
        },
        setVeterinary(state, veterinary) {
            state.queryForm.veterinary_id = veterinary;
        },

        setSpecialty(state, specialty) {
            state.queryForm.specialty_id = specialty;
        },
        setStartDay(state, day) {
            state.queryForm.startDay = day;
        },
        setEndDay(state, day) {
            state.queryForm.endDay = day;
        },
        setStartHour(state, hour) {
            state.queryForm.startHour = hour;
        },
        setEndHour(state, hour) {
            state.queryForm.endHour = hour;
        },
        setIsFiltereds(state, filtered) {
            state.isFiltered = filtered;
        },
        setIsdataLoaded(state, loaded) {
            state.isDataLoaded = loaded;
        },
        updateDaysAvailable(state, loaded) {
            state.daysAvailable = loaded;
        },

        resetAllData(state) {
            state.scheduleAvailable = null;
            state.schedules = [];
            state.day = "";
            state.queryForm = {
                type: "",
                branch_office_id: "0",
                veterinary_id: "0",
                specialty_id: "0",
                startDay: "",
                endDay: "",
                startHour: "",
                endHour: "",
            };
            state.client = {
                rut: null,
                first_name: "",
                last_name: "",
                phone: "",
                email: "",
                reason: "",
                registro: "Cliente",
                nivel: "",
                pet_id: null,
                pets: [],
            };
            state.date = {
                day: "",
                hour: "",
                office: "",
                petName: "",
                veterinary: "",
                reason: "",
            };
        },
        resetInitData(state) {
            state.branchs = state.initData.branchs;
            state.veterinaries = state.initData.veterinaries;
            state.specialties = state.initData.specialties;
            state.queryForm = {
                type: "",
                branch_office_id: "0",
                veterinary_id: "0",
                specialty_id: "0",
                startDay: "",
                endDay: "",
                startHour: "",
                endHour: "",
            };
        },
    },
    getters: {
        scheduleAvailable: (state) => {
            return state.scheduleAvailable;
        },
        schedules: (state) => {
            return state.schedules;
        },
        branchs: (state) => {
            return state.branchs;
        },
        veterinaries: (state) => {
            return state.veterinaries;
        },
        specialties: (state) => {
            return state.specialties;
        },
        queryForm: (state) => {
            return state.queryForm;
        },
        day: (state) => {
            return state.day;
        },
        reasons: (state) => {
            return state.reasons;
        },
        species: (state) => {
            return state.species;
        },
        isDataLoaded: (state) => {
            return state.isDataLoaded;
        },
        isFiltered: (state) => {
            return state.isFiltered;
        },
        date: (state) => {
            return state.date;
        },
        daysAvailable: (state) => {
            return state.daysAvailable;
        },
        client: (state) => {
            return state.client;
        },
    },
});
new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
