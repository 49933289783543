import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUserMd,
  faDog,
  faSlash,
  faStoreAltSlash,
  faStethoscope,
  faBuilding,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faUserMd, faChevronLeft, faBuilding, faDog, faSlash, faStoreAltSlash, faStethoscope);

Vue.component("font-awesome-icon", FontAwesomeIcon);
