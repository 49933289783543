<template>
  <div id="app">
    <div class="container">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  /* text-align: center; */
  color: #2c3e50;
}
.back {
  text-decoration: none;
  color: #2c3e50;
  position: absolute;
  top: 6%;
  left: 7%;
  cursor: pointer;
}

body {
  margin: 0;
  font-size: 1rem;
  background: #f3f3f3 url(https://assets.jumpseller.com/store/pethappy/themes/25289/bg2.png?1391443181) center top
    repeat-x;
}
.container {
  font-family: "Open Sans", "Arial";
  position: relative;
  margin: 0 auto;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  border-radius: 0.25rem;
  border: 1px solid #dee2e6;
  background-color: #fff;
  margin-top: 7.5rem;
  margin-bottom: 7.5rem;
  padding-top: 70px !important;
  padding-left: 8% !important;
  padding-right: 8% !important;
  padding-bottom: 70px;
}
.card-container {
  display: flex;
  flex-wrap: wrap;
}

.search {
  width: 100%;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.mt-5,
.my-5 {
  margin-top: 5% !important;
}
.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
</style>
